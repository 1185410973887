// MANAGED BY App.js
import { useState, useCallback, useMemo, useRef, useEffect, useContext } from 'react'
import { history } from 'instantsearch.js/es/lib/routers'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import {
  InstantSearch,
  SearchBox,
  Configure,
  HierarchicalMenu,
  Pagination,
  RefinementList,
  Stats,
  SortBy,
  useInstantSearch,
} from 'react-instantsearch'
import { Toast } from 'primereact/toast'

import ClearRefinements from './ClearRefinements'
import ToggleRefinement from './ToggleRefinement'
import CustomHits from './CustomHits'
import { humanize, getMeilisearchConfig } from '../entries/utils'
import { sortingOptions, indexName } from './config'
import Autograb from './Autograb'
import LeadsView from './LeadsView'
import MobileFilters from './MobileFilters'
import showToast from '../shared/ShowToast'
import Loading from '../Loading'
import { useParams, Link } from 'react-router-dom'
import { DealershipContext, WebsiteContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'

const savedToggle = JSON.parse(localStorage.getItem(`toggle_${window.dealer_slug}`)) || {}
// Could not use DealershipContext here as it should be used in the App component
const savedRefinements = JSON.parse(localStorage.getItem(`refinementList_${window.dealer_slug}`)) || {}
const savedHierarchicalMenu = JSON.parse(localStorage.getItem(`hierarchicalMenu_${window.dealer_slug}`)) || {}
const savedSortBy = localStorage.getItem(`sortBy_${window.dealer_slug}`) || `${indexName}:custom_rank:desc`
const defaultStatus = ['in_stock', 'coming_soon']

const ToggleRefinements = () => {

  let [showAll, setShowAll] = useState(false)

  return (
    <div className="search-panel">
      <div className="d-flex">
        <h5>Filters</h5>
        <div className="ml-auto small" onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show Less' : 'Show More'}
        </div>
      </div>
      <ToggleRefinement
        attribute="show_on_dealer_website"
        label="Load to Website"
        on={true}
      />
      {showAll && <ToggleRefinement
        attribute="show_on_dealer_website"
        label="NOT on Website"
        on={false}
      />}
      <ToggleRefinement
        attribute="load_to_autotrader"
        label="Load to Autotrader"
        on={true}
      />
      {showAll && <ToggleRefinement
        attribute="load_to_autotrader"
        label="NOT on Autotrader"
        on={false}
      />}
      <ToggleRefinement
        attribute="load_to_autogate"
        label="Load to Autogate"
        on={true}
      />
      {showAll && <ToggleRefinement
        attribute="load_to_autogate"
        label="NOT on Autogate"
        on={false}
      />}
      <ToggleRefinement
        attribute="load_to_facebook"
        label="Load to Facebook Marketplace"
        on={true}
      />
      <ToggleRefinement
        attribute="needs_description"
        label="Needs Dealer Comments?"
        on={true}
      />
      <ToggleRefinement
        attribute="restrict_third_party"
        label="OEM Restrictions"
        on={true}
      />
      <ToggleRefinement attribute="has_redbook" label="Missing Redbook" on={false} />
      <ToggleRefinement attribute="price" label="Missing Price" on={0} />
      <ToggleRefinement attribute="needs_photos" label="No Photos" on={true} />
      <ToggleRefinement attribute="total_image_count" label="No Photos OR stock photos" on={0} />
      <ToggleRefinement attribute="reserved" label="Reserved" on={true} />
      <ToggleRefinement attribute="preorder" label="Preorder" on="true" />
      <ToggleRefinement attribute="certified" label="Certified" on="true" />
    </div>
  )
}

const routing = {

  router: history(),
  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[savedSortBy]
      const refinementList = indexUiState?.refinementList || {}
      const refinementListString = JSON.stringify(refinementList)
      localStorage.setItem(`refinementList_${window.dealer_slug}`, refinementListString)
      const hierarchicalMenu = indexUiState.hierarchicalMenu || {}
      const hierarchicalMenuString = JSON.stringify(hierarchicalMenu)
      localStorage.setItem(`hierarchicalMenu_${window.dealer_slug}`, hierarchicalMenuString)
      const sortBy = indexUiState.sortBy || savedSortBy
      localStorage.setItem(`sortBy_${window.dealer_slug}`, sortBy)
      const savedToggle = JSON.parse(localStorage.getItem(`toggle_${window.dealer_slug}`)) || {}

      return {
        q: indexUiState.query,
        page: indexUiState.page,
        hierarchicalMenu: indexUiState?.hierarchicalMenu || savedHierarchicalMenu,
        toggle: savedToggle || indexUiState.toggle,
        refinements: indexUiState?.refinementList || savedRefinements,
      }
    },
    routeToState(routeState) {
      return {
        [savedSortBy]: {
          query: routeState.q,
          page: routeState.page,
          refinementList: {
            ...routeState.refinements,
            ...(Object.keys(savedRefinements).length !== 0 ? savedRefinements : {}),
            status: (Object.keys(savedRefinements).length !== 0 ? savedRefinements.status : routeState?.refinementList?.status) || defaultStatus,
          },
          hierarchicalMenu: savedHierarchicalMenu || routeState.hierarchicalMenu,
          toggle: savedToggle || routeState.toggle,
        },
      }
    },
  },
}

const AddCarBtn = ({ className = 'btn btn-outline-success ml-2' }) => {
  const { dealership } = useContext(DealershipContext)
  return (
    <Link to={`/dealerships/${dealership?.slug}/cars/new`} className={className}>
      <i className="fa fa-plus mr-2" />
      Car
    </Link>
  )
}

function LoadingIndicator() {
  const { status } = useInstantSearch()

  if (status === 'stalled') {
    return (
      <div className="py-3 mb-0 text-secondary">
        <Loading />
      </div>
    )
  }
  return null
}

const ReindexLink = () => {
  const { dealership } = useContext(DealershipContext)

  let reindexLink = `/dealerships/${dealership?.slug}/cars/reindex_cars`
  return (
    <a href={reindexLink} className="dropdown-item">
      Reindex Cars
    </a>
  )
}

const App = ({ searchFilters, hasAutograb, carSwitches, beta }) => {
  const [selectedHits, setSelectedHits] = useState([])
  const [autograb, setAutograb] = useState()
  const [leadsView, setLeadsView] = useState()
  const [recentlyUpdatedCars, setRecentlyUpdatedCars] = useState([])
  const notification = useRef(null)
  const { website } = useContext(WebsiteContext)
  const { dealership } = useContext(DealershipContext)
  let { dealershipSlug } = useParams()

  useFetchDealership()

  const { searchClient, filters, index } = useMemo(() => {
    const { meilisearchHost, meilisearchApiKey } = getMeilisearchConfig()
    const index = localStorage.getItem(`sortBy_${window.dealer_slug}`) || `${indexName}:custom_rank:desc`
    let filters = searchFilters
    const { searchClient } = instantMeiliSearch(meilisearchHost, meilisearchApiKey)

    if (website?.id) filters += ` AND website_id=${website.id}`

    return { searchClient, filters, index }
  }, [indexName]) // Add any other dependencies if they exist

  // The recentlyUpdatedCars is our solution to the potential delay between postgres being updated
  // And meilisearch being updated (which can under particular circumstances take a minute or two)
  // The server sends down any cars updated in the last 10 minutes, so if a user refreshes the page
  // prior to meilisearch being updated the correct data will be shown
  const addRecentlyUpdatedCar = (car) => {
    setRecentlyUpdatedCars([...recentlyUpdatedCars.filter((c) => c.id !== car.id), car])
  }

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/cars/recently_updated_cars.json`)
      .then(res => res.json())
      .then(res => setRecentlyUpdatedCars(res.recentlyUpdatedCars))
  }, [])

  // Add in UI notifcation to let users know the job is queued
  function handleSelectHit(carId) {
    setSelectedHits((prevSelectedHits) => {
      if (prevSelectedHits.includes(carId)) {
        return prevSelectedHits.filter((id) => id !== carId)
      } else {
        return [...prevSelectedHits, carId]
      }
    })
  }

  function printSelectedHits(selectedHits) {
    if (selectedHits.length === 0) {
      showToast(
        notification,
        'warning',
        'No documents have been selected',
        'Please select documents to export.'
      )
      return
    }
    fetch('/cars/export_pdfs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ car_ids: selectedHits }),
    })
      .then((response) => response.json())
      .then((data) => {
        showToast(
          notification,
          'success',
          'Documents are being exported',
          'You will receive an email when they are ready.'
        )
      })
      .catch((error) => {
        console.error(error)
        showToast(notification, 'error', 'Error exporting documents', 'Please contact support.')
      })
  }

  // useCallback was introduced to prevent component re-render issues
  // when setSelectedHits or setAutograb are called
  const humanizeTransformItems = useCallback((items) => {
    return items.map((item) => ({
      ...item,
      label: humanize(item.label),
    }))
  }, [])

  return (
    <div className=" bg-light container-fluid pt-3">
      <InstantSearch
        indexName={index}
        searchClient={searchClient}
        routing={routing}
        initialUiState={{
          [`${index}`]: {
            refinementList: {
              status: ['in_stock', 'coming_soon'],
            },
            sortBy: `${indexName}:stocknum:asc`,
          },
        }}
      >
        <Toast ref={notification} />
        <Configure filters={filters} hitsPerPage={40} />
        <div className="row no-gutters">
          <div className="w-100 col-xs-12 d-block d-md-none">
            <MobileFilters humanizeTransformItems={humanizeTransformItems} />
          </div>
          <div className="col-md-3 d-none d-lg-block">
            <div className="rounded border bg-white mr-3 mb-3">
              <div className="search-panel">
                <h5>Search</h5>
                <SearchBox placeholder="Stock number, Make, model..." />
              </div>
              <div className="p-2">
                <ClearRefinements
                  translations={{
                    resetButtonText: 'Clear Filters',
                  }}
                  excludedAttributes={['status']}
                />
              </div>
              <div className="search-panel">
                <h5>Sorting</h5>
                <SortBy items={sortingOptions} />
              </div>
              <div className="search-panel" id="search-status">
                <RefinementList attribute="status" transformItems={humanizeTransformItems} />
              </div>
              <div className="search-panel">
                <h5>Item Type</h5>
                <RefinementList attribute="item_type" transformItems={humanizeTransformItems} />
              </div>
              <div className="search-panel">
                <h5>Status</h5>
                <RefinementList attribute="car_type" transformItems={humanizeTransformItems} />
              </div>
              <div className="search-panel">
                <h5>Location Name</h5>
                <RefinementList attribute="location_name" showMore={true} showMoreLimit={100} />
              </div>
              <div className="search-panel">
                <h5>Colour</h5>
                <RefinementList attribute="colour" showMore={true} showMoreLimit={100} />
              </div>
              <div className="search-panel">
                <h5>Make/Model</h5>
                <HierarchicalMenu
                  attributes={['categories.lvl0', 'categories.lvl1', 'categories.lvl2']}
                  sortBy={['isRefined']}
                  limit={500}
                />
              </div>
              <div className="search-panel">
                <h5>Carsales Account</h5>
                <RefinementList attribute="carsales_account" showMore={true} />
              </div>
              <div className="search-panel">
                <h5>Source</h5>
                <RefinementList
                  attribute="source"
                  transformItems={(items) =>
                    items.map((item) => ({
                      ...item,
                      label: humanize(item.label),
                    }))
                  }
                />
              </div>
              <div className="search-panel">
                <h5>Body</h5>
                <RefinementList attribute="simple_body" showMore={true} showMoreLimit={100} />
              </div>
              <div className="search-panel">
                <h5>City</h5>
                <RefinementList attribute="city" showMore={true} />
              </div>
              {!dealership?.slug && (
                <div className="search-panel">
                  <h5>Dealership</h5>
                  <RefinementList attribute="dealership_name" showMore={true} />
                </div>
              )}
              <div className="search-panel">
                <h5>Fuel Source</h5>
                <RefinementList attribute="simple_fuel" showMore={true} />
              </div>
              <div className="search-panel">
                <h5>Tag</h5>
                <RefinementList attribute="tag" showMore={true} />
              </div>
              <div className="search-panel">
                <h5>Categories</h5>
                <RefinementList attribute="categories" showMore={true} />
              </div>
              {hasAutograb && (
                <div className="search-panel">
                  <h5>Car Grade</h5>
                  <RefinementList attribute="car_grade" />
                </div>
              )}
              <ToggleRefinements />
              <div className="search-panel">
                <h5>360 Spin</h5>
                <RefinementList attribute="has_spin" />
              </div>
            </div>
          </div>
          {hasAutograb && <Autograb autograb={autograb} setAutograb={setAutograb} />}
          <LeadsView leadsView={leadsView} setLeadsView={setLeadsView} />
          <div className="col-md-12 col-lg-9">
            <div className="pb-3">
              <div className="box p-3 d-flex justify-content-between align-items-center">
                <div className="small text-secondary d-md-flex align-items-center d-none">
                  <Stats />
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-none d-md-block">
                    <div className="dropdown ml-2">
                      <button
                        className={`btn btn-info dropdown-toggle ${selectedHits.length === 0 ? 'disabled' : ''}`}
                        disabled={selectedHits.length === 0}
                        data-toggle="dropdown"
                      >
                        {selectedHits.length} Cars
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <button className="dropdown-item" onClick={() => printSelectedHits(selectedHits)}>
                          Print Selected Hits
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-none d-md-block">
                    <div className="btn-group btn-block">
                      <AddCarBtn />
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-outline-success dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <Link to={`/dealerships/${dealership?.slug}/cars/reports`} className="dropdown-item">
                          Reports
                        </Link>
                        <ReindexLink />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-md-none w-100">
                  <AddCarBtn className="btn btn-block btn-success" />
                </div>
              </div>
              <LoadingIndicator />
              <CustomHits
                onSelect={handleSelectHit}
                setAutograb={setAutograb}
                beta={beta}
                hasAutograb={hasAutograb}
                recentlyUpdatedCars={recentlyUpdatedCars}
                carSwitches={carSwitches}
                setLeadsView={setLeadsView}
                addRecentlyUpdatedCar={addRecentlyUpdatedCar}
              />
              <Pagination />
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  )
}

const Wrapper = () => {
  let [params, setParams] = useState(false)

  const { dealershipSlug } = useParams()

  useEffect(() => {
    if (params) return

    fetch(`/dealerships/${dealershipSlug}/cars/react_index_params`)
      .then(res => res.json())
      .then(data => {
        setParams(data)
      })
  }, [])

  if (!params) {
    return (
      <div className="p-3 mb-0 text-secondary">
        <Loading />
      </div>
    )
  }

  return (
    <App {...params} carSwitches={params.car_switches} />
  )

}

export default Wrapper
